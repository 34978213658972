import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  Button,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import BottomLayout from "@/layout/BottomLayout";
import { HOST_URL } from "@/utils/constant";

const { useState } = React;

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/sales`}
      title="Convert Prospects to Customers More Effectively | Airgram for Sales Leaders"
      description="Airgram helps sales leaders connect, demonstrate and close deals. Let Airgram reduce all the required manual work."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Help your sales team facilitate their work during the connecting, pitching, and closing process"
          buttonText="Get Started Free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Turn prospects into customers more effectively
        </Text>
        <Text textAlign="center" fontSize={18}>
          Connect, demonstrate and convert - let Airgram reduce all the required
          manual work.
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1030x711/a19d8edb56/productusecasesales1.png"
              alt="Use Case Team"
            />
          }
          title="Build a connection with leads quickly"
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Connect with your leads with the help of one-click recording an
              instant Zoom call. Or auto join Zoom calls with an AI assistant.
              Focus more on listening and thinking instead of scribbling notes.
            </Text>
            <Text fontSize={18}>
              Review all the details during the pitching process thoroughly by
              utilizing downloadable HD video recordings.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Less hassle of presenting your product to leads"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/995x550/21e36e2ba9/productusecasesales2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Whether giving a presentation or demonstration of your products,
              real-time transcription with speaker’s detection helps you engage
              with your leads wholeheartedly.
            </Text>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Convert prospects into customers with less effort"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/1133x667/1a6833aed0/productusecasesales3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Facilitate sales process to seal more deals. AI assistant extracts
              all entity words such as price, number, date, names, etc. Locating
              different quotes or proposals becomes such a breeze.
            </Text>
            <Text fontSize={18}>
              Higher efficiency at extracting key information significantly
              pushes the conversion process ahead.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
